import { StarFilled } from '@ant-design/icons';
import { Card, Col, Flex, Row, Space, Typography } from "antd";
import { motion } from 'framer-motion';
import { range } from "lodash-es";

export const HomeReview = () => {
    const items = [
        {
            name: '해이맘',
            star: 5,
            content: '큐브 관리되는 거 짱짱 좋아요!\n이유식 앱 여러 개 써봤는데\n이게 간편하고 제일 좋은 것 같아요!'
        },
        {
            name: 'Eunok Jeon',
            star: 5,
            content: '큐브를 등록하고 유통기한 설정할 수 있고 메뉴표를 손쉽게 등록할 수 있어편리합니다.\n이유식 재료에 대한 반응도 각각 입력할 수 있고 캘린더에 처음 테스트된 재료가 표시되는 것도 마음에 듭니다.'
        },
        {
            name: '로로둥이',
            star: 5,
            content: '이유식 식단 표랑 큐브 관리를 수기로 하다가 스트레스받던 찰나,\n여러 이유식 달력 앱을 다운로드해 사용해 보던 중 ‘맘마플래너’가 제 맘에 쏙 들더라고요.\n며칠 안되었지만 너무 감사하게 잘 쓰고 있어요.'
        },
        {
            name: '하윤맘',
            star: 5,
            content: '큐브관리도 되고 해당큐브를 여러개 선택하면 자동으로 식단표 이름도 만들어지고,\n또 큐브 소진해야 하는 디데이도 알려주고 너무너무 좋습니다'
        },
        {
            name: '유나맘',
            star: 5,
            content: '식단 관리뿐만 아니라 다른 아기들은 무엇을 먹였는지 구경 가능한 데다가\n배우자와 공유도 할 수 있어서 여러모로 유용해요~'
        },
        {
            name: '서우서연',
            star: 5,
            content: '알러지반응을 볼 겸 이유식 계획을 세울 겸 썼던 맘마플래너 어플인데,\n막상 알러지 반응이 나타나니 원인을 바로 확인할 수 있어서 쓰길 잘한 것 같아요.'
        },
        {
            name: '❤️시우❤️',
            star: 5,
            content: '어떤 식재료를 테스트할지 고민됐는데 곡류, 육류, 채소류, 과일류 등 이유식 식재료를 식품군별로 확인할 수 있어서 좋아요~'
        },
    ]

    return (
        <Row gutter={[32, 48]} className="home-review">
            <Col {...{ xs: 0, sm: 0, md: 24 }}>
                <Flex justify="center">
                    <Space direction="vertical" style={{ textAlign: 'center' }}>
                        <Space size={'small'}>
                            <Typography.Text strong style={{ fontSize: 50, fontWeight: 700, color: '#000' }}>
                                벌써
                            </Typography.Text>
                            <Typography.Text strong style={{ fontSize: 50, fontWeight: 700, color: '#1EB030' }}>
                                1만 명+ 넘는
                            </Typography.Text>
                        </Space>
                        <Space size={1}>
                            <Typography.Text strong style={{ fontSize: 50, fontWeight: 700, color: '#000' }}>
                                엄마들이 사용 중이에요.
                            </Typography.Text>
                        </Space>
                    </Space>
                </Flex>
            </Col>
            <Col {...{ xs: 24, sm: 24, md: 0 }}>
                <Flex justify="center">
                    <Space direction="vertical" style={{ textAlign: 'center' }}>
                        <Space size={'small'}>
                            <Typography.Text strong style={{ fontSize: 38, fontWeight: 700, color: '#000' }}>
                                벌써
                            </Typography.Text>
                            <Typography.Text strong style={{ fontSize: 38, fontWeight: 700, color: '#1EB030' }}>
                                1만 명+ 넘는
                            </Typography.Text>
                        </Space>
                        <Space size={1}>
                            <Typography.Text strong style={{ fontSize: 38, fontWeight: 700, color: '#000' }}>
                                엄마들이 사용 중이에요.
                            </Typography.Text>
                        </Space>
                    </Space>
                </Flex>
            </Col>
            <Col span={24}>
                <motion.div
                    style={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        position: "absolute",
                    }}
                    animate={{
                        x: ["0%", "-100%"],
                    }}
                    transition={{
                        repeat: Infinity, // 무한 반복
                        duration: 300,
                        ease: "linear",
                    }}
                >
                    {range(1, 100).map((i) => {
                        const value = items[(i - 1) % items.length];

                        return (
                            <Card
                                key={i}
                                hoverable
                                bordered
                                style={{ marginLeft: 12, marginRight: 12, minWidth: 280 }}
                            >
                                <Space direction="vertical" size={'large'}>
                                    <Space size={12}>
                                        <Space size={1}>
                                            <StarFilled style={{ color: '#FFC900' }} />
                                            <StarFilled style={{ color: '#FFC900' }} />
                                            <StarFilled style={{ color: '#FFC900' }} />
                                            <StarFilled style={{ color: '#FFC900' }} />
                                            <StarFilled style={{ color: '#FFC900' }} />
                                        </Space>
                                        <Typography.Text>
                                            {value.name}
                                        </Typography.Text>
                                    </Space>
                                    <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
                                        {value.content}
                                    </Typography.Text>
                                </Space>
                            </Card>
                        )
                    })}
                </motion.div>
            </Col>
        </Row>
    )
}