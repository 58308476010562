import { Col, Flex, Layout, Row, Space, Typography, Image } from "antd"
import { FunctionComponent } from "react"
import WhiteLogo from "../assets/images/logo/white_logo.png"

export const Footer: FunctionComponent = () => {
    return (
        <footer className="footer">
            <Layout.Content style={{ maxWidth: 1200, margin: '0 auto' }}>
                <Row>
                    <Col {...{ xs: 0, md: 0, lg: 24 }}>
                        <Flex justify="start">
                            <Space direction="vertical">
                                <Space>
                                    <Image
                                        preview={false}
                                        alt="logo"
                                        src={WhiteLogo}
                                        style={{
                                            width: 26,
                                            height: 26,
                                        }}
                                    />
                                    <Typography.Text style={{ fontSize: 26, color: '#fff' }}>맘마플래너</Typography.Text>
                                </Space>
                                <Flex justify="start">
                                    <Space size={'middle'} split={'|'}>
                                        <Typography.Link
                                            href="/privacy-terms"
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            개인정보처리방침
                                        </Typography.Link>
                                        <Typography.Link
                                            href="/service-terms"
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            서비스 약관
                                        </Typography.Link>
                                    </Space>
                                </Flex>
                                <Flex justify="start">
                                    <Space size={'middle'}>
                                        <Typography.Text
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            문의
                                        </Typography.Text>
                                        <Typography.Link
                                            href="mailto:mommaplanner@gmail.com"
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            mommaplanner@gmail.com
                                        </Typography.Link>
                                    </Space>
                                </Flex>
                                <Typography.Text style={{ fontSize: 16, color: '#fff' }}>copyright © Mommaplanner. All rights reserved.</Typography.Text>
                            </Space>
                        </Flex>
                    </Col>
                    <Col {...{ xs: 24, sm: 24, md: 0, lg: 0, xl: 0, xxl: 0 }}>
                        <Flex justify="start">
                            <Space direction="vertical">
                                <Space>
                                    <Image
                                        preview={false}
                                        alt="logo"
                                        src={WhiteLogo}
                                        style={{
                                            width: 26,
                                            height: 26,
                                        }}
                                    />
                                    <Typography.Text style={{ fontSize: 26, color: '#fff' }}>맘마플래너</Typography.Text>
                                </Space>
                                <Flex justify="start">
                                    <Space size={'middle'} split={'|'}>
                                        <Typography.Link
                                            href="/privacy-terms"
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            개인정보처리방침
                                        </Typography.Link>
                                        <Typography.Link
                                            href="/service-terms"
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            서비스 약관
                                        </Typography.Link>
                                    </Space>
                                </Flex>
                                <Flex justify="start">
                                    <Space size={'middle'}>
                                        <Typography.Text
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            문의
                                        </Typography.Text>
                                        <Typography.Link
                                            href="mailto:mommaplanner@gmail.com"
                                            style={{ fontSize: 16, color: '#fff' }}
                                        >
                                            mommaplanner@gmail.com
                                        </Typography.Link>
                                    </Space>
                                </Flex>
                                <Typography.Text style={{ fontSize: 16, color: '#fff' }}>copyright © Mommaplanner. All rights reserved.</Typography.Text>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
            </Layout.Content>
        </footer>
    )
}