import { Col, Divider, Row, Skeleton, Space, Typography } from "antd"
import { FunctionComponent, useEffect, useState } from "react"
import InfiniteScroll from 'react-infinite-scroll-component'
import '../../styles/notice.scss'
import { Errors } from "../../contexts/ErrorContext"
import { NoticeModel } from "../../model/NoticeModel"
import { Pagination } from "../../model/PaginationModel"
import { NoticeService } from "../../service/NoticeService"
import DatetimeUtility from "../../util/DatetimeUtility"
const { Text, Title } = Typography

export const Notices: FunctionComponent = () => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<Pagination<NoticeModel.INoticeModel> | null>()
    const [items, setItems] = useState<Array<NoticeModel.INoticeModel>>([])

    const search = async (page: number = 1) => {
        setLoading(true)
        const response = await NoticeService.search({ page: page })
        if (response.status === 200) {
            setPage(response.data.item)
            setItems([...items, ...response.data.item.items])
        } else {
            Errors.AjaxError(response.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        search()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Row gutter={[0, 0]} justify={"center"} className={"notice-container"}>
            <Col span={24} style={{ padding: "0px 16px" }}>
                <Row gutter={[0, 24]} align="middle">
                    <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }}>
                        <Title level={2}>공지사항</Title>
                    </Col>
                    {(isLoading && !page) && (
                        <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }}>
                            <Skeleton active />
                            <Skeleton active />
                            <Skeleton active />
                            <Skeleton active />
                        </Col>
                    )}
                </Row>
                {page && (
                    <InfiniteScroll
                        dataLength={items.length}
                        next={() => {
                            if (page.nextPageIndex <= 0) return
                            search(page.nextPageIndex)
                        }}
                        hasMore={page.hasNext}
                        loader={isLoading ? <Skeleton paragraph={{ rows: 1 }} active /> : null}
                    >
                        <Row gutter={[0, 24]} align="middle">
                            {items.map((item, idx: number) => {
                                return (
                                    <Col key={idx} className="notice-item" xs={{ span: 24 }} lg={{ span: 8, offset: 8 }} onClick={() => { window.location.href = `/notices/${item.id}` }}>
                                        <Space direction={"vertical"}>
                                            <Title level={5} style={{ fontWeight: 400 }}>{item.title}</Title>
                                            <Text>{DatetimeUtility.toShortDateString(item.created, "YYYY/MM/DD")}</Text>
                                        </Space>
                                        <Divider style={{ marginBottom: 0 }} />
                                    </Col>
                                )
                            })}
                        </Row>
                    </InfiniteScroll>
                )}
            </Col>
        </Row>
    )
}