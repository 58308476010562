import { Col, Flex, Layout, Row, Space, Typography, Image, Button } from "antd"
import { FunctionComponent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import WhiteLogo from "../assets/images/logo/white_logo.png"

export const Navigation: FunctionComponent = () => {
    const [show, setShow] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener("scroll", handleNavbar)
        return () => {
            window.removeEventListener("scroll", handleNavbar)
        }
    }, [])

    const handleNavbar = () => {
        window.scrollY > 50 ? setShow(true) : setShow(false)
    }

    const link = () => {
        const userAgent = navigator.userAgent

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return "https://apps.apple.com/kr/app/%EB%A7%98%EB%A7%88%ED%94%8C%EB%9E%98%EB%84%88/id6443713917"
        } else if (/android/i.test(userAgent)) {
            return "https://play.google.com/store/apps/details?id=com.appg.mommaplanner&hl=ko"
        } else {
            return null
        }
    }

    return (
        <Layout.Header className={`${show ? "ant-layout-sticky-header" : ""}`}>
            <Layout.Content>
                <Row className="top" justify={"space-between"} align={"middle"} style={{ height: '100%' }}>
                    <Col
                        {...{ xs: 14, md: 8, lg: 4, }}
                        onClick={() => {
                            navigate("/")
                        }}
                    >
                        <Flex align='center' style={{ gap: 8, cursor: 'pointer' }}>
                            <Image
                                preview={false}
                                alt="logo"
                                src={WhiteLogo}
                                style={{
                                    width: 26,
                                    height: 26,
                                }}
                            />
                            <Typography.Text strong style={{ fontSize: 26, }}>
                                {'맘마플래너'}
                            </Typography.Text>
                        </Flex>
                    </Col>
                    <Col
                        {...{ xs: 8, md: 8, lg: 0, }}
                        onClick={() => {
                            navigate("/")
                        }}
                    >
                        <Flex justify="end" align="middle">
                            <Button
                                size="middle"
                                ghost
                                // type="primary"
                                href={link() ?? '#'}
                            >
                                {'앱 다운로드'}
                            </Button>
                        </Flex>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout.Header>
    )
}