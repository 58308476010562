import { Col, Flex, Image, Layout, Row, Space, Typography } from "antd"
import Feature1 from "../../assets/images/service/feature_1.png"
import Feature2 from "../../assets/images/service/feature_2.png"
import Feature3 from "../../assets/images/service/feature_3.png"
import Feature4 from "../../assets/images/service/feature_4.png"
import Feature5 from "../../assets/images/service/feature_5.png"
import Text1 from "../../assets/images/service/text_1.png"
import Text2 from "../../assets/images/service/text_2.png"
import Text3 from "../../assets/images/service/text_3.png"
import Text4 from "../../assets/images/service/text_4.png"
import Text5 from "../../assets/images/service/text_5.png"

export const HomeService = () => {
    return (
        <Row className="home-service">
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Row data-aos={"fade-up"}>
                        <Col {...{ xs: 0, sm: 0, md: 24 }}>
                            <Flex justify="center">
                                <Space direction="vertical" style={{ textAlign: 'center' }}>
                                    <Space size={1}>
                                        <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#F8B31A' }}>
                                            이유식 시작
                                        </Typography.Title>
                                        <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#000' }}>
                                            부터 막막하다면?
                                        </Typography.Title>
                                    </Space>
                                    <Space size={1}>
                                        <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#F8B31A' }}>
                                            맘마플래너로 쉽게 편하게
                                        </Typography.Title>
                                    </Space>
                                    <Space size={1}>
                                        <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#000' }}>
                                            해결하세요.
                                        </Typography.Title>
                                    </Space>
                                </Space>
                            </Flex>
                        </Col>
                        <Col {...{ xs: 24, sm: 24, md: 0 }}>
                            <Flex justify="center">
                                <Space direction="vertical" style={{ textAlign: 'center' }}>
                                    <Space size={1} direction="vertical">
                                        <Typography.Title level={1} style={{ fontSize: 42, fontWeight: 700, color: '#F8B31A' }}>
                                            이유식 시작 부터
                                        </Typography.Title>
                                        <Typography.Title level={1} style={{ fontSize: 42, fontWeight: 700, color: '#000' }}>
                                            막막하다면?
                                        </Typography.Title>
                                    </Space>
                                    <Space size={1} direction="vertical">
                                        <Typography.Title level={1} style={{ fontSize: 42, fontWeight: 700, color: '#F8B31A' }}>
                                            맘마플래너로
                                        </Typography.Title>
                                        <Typography.Title level={1} style={{ fontSize: 42, fontWeight: 700, color: '#F8B31A' }}>
                                            쉽게 편하게
                                        </Typography.Title>
                                    </Space>
                                    <Space size={1}>
                                        <Typography.Title level={1} style={{ fontSize: 42, fontWeight: 700, color: '#000' }}>
                                            해결하세요.
                                        </Typography.Title>
                                    </Space>
                                </Space>
                            </Flex>
                        </Col>
                    </Row>
                    <Row gutter={[32, 140]} justify={'center'} style={{ maxWidth: 1100, margin: '124px auto' }}>
                        <Row gutter={[40, 42]} data-aos={"fade-left"}>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center" align="middle">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Feature1}
                                    />
                                </Flex>
                            </Col>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Text1}
                                    />
                                </Flex>
                            </Col>
                        </Row>
                        <Row gutter={[40, 42]} data-aos={"fade-right"}>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center" align="middle">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Text2}
                                    />
                                </Flex>
                            </Col>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Feature2}
                                    />
                                </Flex>
                            </Col>
                        </Row>
                        <Row gutter={[40, 42]} data-aos={"fade-left"}>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center" align="middle">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Feature3}
                                    />
                                </Flex>
                            </Col>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Text3}
                                    />
                                </Flex>
                            </Col>
                        </Row>
                        <Row gutter={[40, 42]} data-aos={"fade-right"}>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center" align="middle">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Text4}
                                    />
                                </Flex>
                            </Col>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Feature4}
                                    />
                                </Flex>
                            </Col>
                        </Row>
                        <Row gutter={[40, 42]} data-aos={"fade-left"}>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center" align="middle">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Feature5}
                                    />
                                </Flex>
                            </Col>
                            <Col {...{ xs: 24, md: 12 }}>
                                <Flex justify="center">
                                    <Image
                                        preview={false}
                                        style={{ maxWidth: 960, }}
                                        src={Text5}
                                    />
                                </Flex>
                            </Col>
                        </Row>
                    </Row>
                </Layout.Content>
            </Col>
        </Row>
    )
}