import { Button, Col, Flex, Image, Row, Space, Typography } from "antd"
import AndroidIcon from "../../assets/images/android.png"
import AppleIcon from "../../assets/images/apple.png"
import { LettersPullUp } from "../../component/animation/LettersPullUp"

export const HomeHeader = () => {
    const height = window.innerHeight
    const width = window.innerWidth

    const link = () => {
        const userAgent = navigator.userAgent

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return "https://apps.apple.com/kr/app/%EB%A7%98%EB%A7%88%ED%94%8C%EB%9E%98%EB%84%88/id6443713917"
        } else if (/android/i.test(userAgent)) {
            return "https://play.google.com/store/apps/details?id=com.appg.mommaplanner&hl=ko"
        } else {
            return null
        }
    }

    return (
        <Row
            className="home-header"
        >
            <Col
                span={24}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: '#000',
                    opacity: 0.4,
                    zIndex: 1,
                }}
            />
            <Col
                span={24}
                className="parallax"
                style={{
                    zIndex: 2,
                    height: width > 576 ? height : height,
                    backgroundPosition: width > 576 ? 'center' : 'left',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                }}
            >
                <Row justify={'center'} align={'middle'}>
                    <Col
                        {...{ xs: 0, md: 0, lg: 24 }}
                        style={{
                            height: width > 576 ? height * 0.55 : height,
                            alignContent: 'center'
                        }}
                    >
                        <Flex justify="center" align="center" style={{ width: '100%', height: '100%' }}>
                            <Space direction="vertical" size={36}>
                                <Space size={'small'} direction="vertical" style={{ textAlign: 'center' }}>
                                    <Typography.Text strong style={{ fontSize: 56, color: '#fff', textAlign: 'center' }}>
                                        <LettersPullUp
                                            text="우리 아이의 똑똑하고"
                                        />
                                    </Typography.Text>
                                    <Typography.Text strong style={{ fontSize: 56, color: '#fff', textAlign: 'center' }}>
                                        <LettersPullUp
                                            text="건강한 성장을 응원합니다."
                                        />
                                    </Typography.Text>
                                </Space>

                                <Space size={24} style={{ textAlign: 'center', width: '100%', justifyContent: 'center' }}>
                                    <Typography.Link
                                        target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.appg.mommaplanner&hl=ko"
                                    >
                                        <Image
                                            preview={false}
                                            src={AndroidIcon}
                                            style={{ width: 160, }}
                                        />
                                    </Typography.Link>
                                    <Typography.Link
                                        target="_blank"
                                        href="https://apps.apple.com/kr/app/%EB%A7%98%EB%A7%88%ED%94%8C%EB%9E%98%EB%84%88/id6443713917"
                                    >
                                        <Image
                                            preview={false}
                                            src={AppleIcon}
                                            style={{ width: 160, }}
                                        />
                                    </Typography.Link>
                                </Space>
                            </Space>
                        </Flex>
                    </Col>
                    <Col
                        {...{ xs: 24, md: 24, lg: 0 }}
                        style={{
                            height: height,
                            alignContent: 'center'
                        }}
                    >
                        <Flex justify="center" align="center" style={{ width: '100%', height: '100%' }}>
                            <Space size={'small'} direction="vertical" style={{ textAlign: 'center' }}>
                                <Typography.Text strong style={{ fontSize: 52, color: '#fff', textAlign: 'center' }}>
                                    <LettersPullUp
                                        text="우리 아이의"
                                    />
                                </Typography.Text>
                                <Typography.Text strong style={{ fontSize: 52, color: '#fff', textAlign: 'center' }}>
                                    <LettersPullUp
                                        text="똑똑하고"
                                    />
                                </Typography.Text>
                                <Typography.Text strong style={{ fontSize: 52, color: '#fff', textAlign: 'center' }}>
                                    <LettersPullUp
                                        text="건강한 성장을"
                                    />
                                </Typography.Text>
                                <Typography.Text strong style={{ fontSize: 52, color: '#fff', textAlign: 'center' }}>
                                    <LettersPullUp
                                        text="응원합니다."
                                    />
                                </Typography.Text>

                                <Button
                                    size="large"
                                    type="primary"
                                    href={link() ?? '#'}
                                    style={{ marginTop: 28 }}
                                >
                                    {'앱 다운로드'}
                                </Button>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}