import { Layout } from "antd"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { HomeHeader } from "./HomeHeader"
import { HomeReview } from "./HomeReview"
import { HomeService } from "./HomeService"

export const Home = () => {
    return (
        <div style={{ height: window.innerHeight }}>
            <MainLayout menuType={MenuType.Home}>
                <Layout.Content className="home">
                    <HomeHeader />
                    <HomeService />
                    <HomeReview />
                </Layout.Content>
            </MainLayout>
        </div>
    )
}